import { z } from 'zod'
import { http } from '../../index'
import { User } from '../account/models'
import { ListenPageSettings, UserSettingsSchema } from '../../../store/models'
import { zDefault } from '../../../utils/zod'
import { promiseDebounce } from '../../../utils/object'
import { type Theme, getTheme } from '../../../services/theme'

export interface Recording extends Recording.ISchema { }

export namespace Recording {
  export const TranscriptionSchema = z.object({
    start: z.number(),
    end: z.number(),
    num_words: z.number(),
    sentences: z.object({
      start: z.number(),
      end: z.number(),
      text: z.string()
    }).array()
  }).array()

  /** Server dataset */
  export const Schema = z.object({
    id: z.string(),

    /** Whether the recording is a reply vocal. e.g. from collect / contact-form / embed */
    isReply: zDefault(z.boolean(), false),

    /** Id of the folder the recording belongs to */
    folderId: z.string().nullable(),

    /**
     * Email associated with recording.
     * When `isReply` is true it's the email of the sender. Otherwise it's of the receiver
     */
    email: zDefault(z.string().nullable(), null),

    /** Subject of recording taken from gmail compose view */
    subject: z.string().nullable(),

    uploaded: z.boolean().default(true),
    uploadDate: z.coerce.date(),

    /** Audio peaks for visualization */
    peaks: zDefault(z.coerce.number().array(), []),
    views: zDefault(z.number(), 0),

    audioUrl: z.string().url(),
    imageUrl: z.string().url(),

    transcription: zDefault(TranscriptionSchema, []),
    transcriptionStatus: z.enum(['complete', 'loading', 'error'])
  })

  export type ITranscription = z.infer<typeof TranscriptionSchema>
  export type ISchema = z.infer<typeof Schema>

  /** Props that can be edited */
  export interface EditableProps {
    subject: string
  }

  export const { parse, safeParse } = Schema
}

/** Audio Folder Schema Type */
export interface Folder extends Folder.ISchema { }

export namespace Folder {
  export const Schema = z.object({
    uuid: z.string().nullable(),
    name: z.string(),
    recordings: zDefault(Recording.Schema.array(), [])
  })
    .transform((props) => {
      const { uuid: id, ...data } = props
      return { ...data, id }
    })

  /** `null` means default folder */
  export type ID = string | null
  export type ISchema = z.infer<typeof Schema>

  // HTTP Methods
  export const get: () => Promise<Folder[]> = promiseDebounce(() =>
    http
      .get<{ folders: Folder[] }>('recordings')
      .then(i => Schema.array().parse(i.folders))
  )

  /** Creates new folder */
  export function create(name: string) {
    return http.post<ISchema>('folder', { name }).then(Schema.parse)
  }

  export function rename(uuid: ID, name: string) {
    return http.put('folder', { uuid, name })
  }

  export function remove(uuid: ID) {
    return http.delete('folder', { uuid })
  }

  /** Moves recording to given folder */
  export function moveItem(folderId: ID, audioId: string) {
    return http.post('recordings', {
      audio_uuid: audioId,
      folder_id: folderId
    })
  }
}

/** Model for /view-vocal/:id endpoint */
export const ListenViewSchema = z.object({
  audio: Recording.Schema,
  user: User.PublicSchema,
  settings: ListenPageSettings
    .merge(UserSettingsSchema.shape.defaults)
    .extend({
      name: zDefault(z.string(), 'Someone'),
      theme: zDefault(z.custom<Theme>(), getTheme())
    })
})

export type IListenViewModel = z.infer<typeof ListenViewSchema>
