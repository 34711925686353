import { z } from 'zod'
import LOGO_URL from '@assets/images/branding/vocal-identity-logo.svg?url'
import { zDefault } from '../../../../utils/zod'

export interface User extends User.ISchema {}

export namespace User {
  const WEBAPP_ORIGIN = import.meta.env.VITE_APP_WEBAPP_ORIGIN

  export const Schema = z.object({
    id: z.coerce.number(),
    name: zDefault(z.string(), 'You'),
    avatarUrl: zDefault(z.string(), LOGO_URL),
    plan: zDefault(z.number(), 0),
    subscribed: z.boolean().default(false),
    domain: zDefault(z.string(), WEBAPP_ORIGIN),
    inviteCode: z.coerce.string(),
    inviteLink: zDefault(z.string().url(), `${WEBAPP_ORIGIN}/share`),

    // Public user model may not have these data
    email: z.string().email(),
    stripeId: z.string(),
    subscriptionMessage: zDefault(z.string(), ''),

    messages: z.object({
      showPaywall: z.boolean().default(false),
      max: zDefault(z.number(), Infinity),
      count: zDefault(z.number(), 0)
    })
  })

  /** User schema without sensitive data */
  export const PublicSchema = Schema.omit({
    email: true,
    stripeId: true,
    subscriptionMessage: true
  })

  /** PlaceHolder user data */
  export function PlaceHolder() {
    return Schema.parse({
      id: 0,
      stripeId: 'test',
      email: 'you@gmail.com',
      messages: {}
    })
  }

  export type ISchema = z.output<typeof Schema>
  export type IPublicSchema = z.output<typeof PublicSchema>

  export const { parse, safeParse } = Schema
}
