import { type App, type Component, type Plugin, createApp as createVue } from 'vue'
import type { Pinia } from 'pinia'
import type { BaseStorage } from '../utils/storage'
import type { Platform } from '../utils/types'
import { usePinia } from '../store'
import { ErrorService } from '../diagnostics/error-service'
import { http } from '../http'
import { useSession } from './session'

interface AppConfig {
  platform: Platform
  storage: BaseStorage

  /**
   * Installs necessary plugins to pinia context.
   * Creates a pinia context if required
   */
  pinia?: Pinia | false

  /** @default true */
  sentry?: boolean

  /** Vue router */
  router?: Plugin<[]>

  mount: string | false
}

export function initApp(app: App<Element>, config: AppConfig) {
  const { pinia, platform, sentry = true, router, mount } = config

  // TODO: Track platform

  // Init pinia
  if (pinia !== false) {
    const plugin = usePinia(pinia)

    // Install pinia if not installed
    if (!app.config.globalProperties.$pinia)
      app.use(plugin)
  }

  if (sentry)
    app.use(ErrorService, { platform })

  if (router)
    app.use(router)

  // Assign http storage
  http.setStorage(config.storage)

  if (mount)
    app.mount(mount)

  // Load session
  useSession()

  return app
}

export function createApp(component: Component<any>, config: AppConfig & { mount?: string }) {
  try {
    initApp(createVue(component), config)
  }
  catch (cause) {
    ErrorService.log(new Error('Error while initializing vue app', { cause }))
  }
}
