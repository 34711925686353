import revive_payload_client_RTnXvvZf3w from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@20.13.0_encoding@0.1.13_eslint@9.5.0_ioredis@5._irvrnhdfmlknzicdfycbgui4hy/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_dw2P4KLhrt from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@20.13.0_encoding@0.1.13_eslint@9.5.0_ioredis@5._irvrnhdfmlknzicdfycbgui4hy/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_q7HNe2sRnq from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@20.13.0_encoding@0.1.13_eslint@9.5.0_ioredis@5._irvrnhdfmlknzicdfycbgui4hy/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_rV6Spt1bWO from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@2.2.12_@nuxt+devtools@1.4.1_rollup@4.21.2_vite@5.4.3_@types+node@22.5.4_sass_rshegh3snjkdyvfee44q4xm3xm/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_csCjUvbeNH from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@20.13.0_encoding@0.1.13_eslint@9.5.0_ioredis@5._irvrnhdfmlknzicdfycbgui4hy/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_rd09mMnLIu from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@20.13.0_encoding@0.1.13_eslint@9.5.0_ioredis@5._irvrnhdfmlknzicdfycbgui4hy/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_P3MJLYYGlT from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.21.2_typescript@5.5.4_vue@3.5.1_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/webapp/.nuxt/components.plugin.mjs";
import prefetch_client_rSqme1WeOT from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@20.13.0_encoding@0.1.13_eslint@9.5.0_ioredis@5._irvrnhdfmlknzicdfycbgui4hy/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import unocss_MzCDxu9LMj from "/vercel/path0/apps/webapp/.nuxt/unocss.mjs";
import titles_teo5eRHfOP from "/vercel/path0/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.10_@lezer+common@1.2.1_@nuxt+devtools@1.4.1_rollup@4.21.2_vite@5.4.3_@ty_4yr5ncyh7fz4nwjau43cdfvd3u/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.mjs";
import defaults_sN1VRBIJ23 from "/vercel/path0/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.10_@lezer+common@1.2.1_@nuxt+devtools@1.4.1_rollup@4.21.2_vite@5.4.3_@ty_4yr5ncyh7fz4nwjau43cdfvd3u/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.mjs";
import siteConfig_J8q0YVxzmZ from "/vercel/path0/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.5_@nuxt+devtools@1.4.1_rollup@4.21.2_vite@5.4.3_@types+node@22._xlngowh6o4jylcm7jox66fcdhm/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.mjs";
import inferSeoMetaPlugin_IhrBBibyaH from "/vercel/path0/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.5_@nuxt+devtools@1.4.1_rollup@4.21.2_vite@5.4.3_@types+node@22._xlngowh6o4jylcm7jox66fcdhm/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.mjs";
import view_transitions_client_XeQL7CYpgd from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@20.13.0_encoding@0.1.13_eslint@9.5.0_ioredis@5._irvrnhdfmlknzicdfycbgui4hy/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_lKNtB1quJs from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@20.13.0_encoding@0.1.13_eslint@9.5.0_ioredis@5._irvrnhdfmlknzicdfycbgui4hy/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_Qgc0NOjd6E from "/vercel/path0/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.0_@pinia+nuxt@0.5.1_rollup@4.21.2_typescript@5.5.4_vue@_p3ckuedlf6tsayfcyusutjjqqq/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import _00_setup_OesUu83x3t from "/vercel/path0/packages/ui/plugins/00.setup.ts";
export default [
  revive_payload_client_RTnXvvZf3w,
  unhead_dw2P4KLhrt,
  router_q7HNe2sRnq,
  _0_siteConfig_rV6Spt1bWO,
  payload_client_csCjUvbeNH,
  check_outdated_build_client_rd09mMnLIu,
  plugin_vue3_P3MJLYYGlT,
  components_plugin_KR1HBZs4kY,
  prefetch_client_rSqme1WeOT,
  unocss_MzCDxu9LMj,
  titles_teo5eRHfOP,
  defaults_sN1VRBIJ23,
  siteConfig_J8q0YVxzmZ,
  inferSeoMetaPlugin_IhrBBibyaH,
  view_transitions_client_XeQL7CYpgd,
  chunk_reload_client_lKNtB1quJs,
  plugin_Qgc0NOjd6E,
  _00_setup_OesUu83x3t
]