import { type MaybeRef, onMounted, toValue } from 'vue'
import { _log } from '../../diagnostics'

type Target = Window | (() => Window)

interface Message<Key = any, Data = any> {
  key: Key
  data?: Data
  secret?: string
}

export type CorsBridgeMethods<T> = Partial<{
  [K in keyof T]: T[K] extends undefined
    ? () => void
    : (data: Message<K, T[K]>['data']) => void
}>

export class CorsBridge<Events> {
  static new<T>(...args: ConstructorParameters<typeof CorsBridge>) {
    return new this<T>(...args)
  }

  constructor(readonly targetWindow: Target, readonly targetOrigin: string, protected readonly secret?: string) {}

  get window() {
    const i = this.targetWindow
    return typeof i === 'function' ? i() : i
  }

  @_log
  send<Key extends keyof Events>(key: Key, data?: Events[Key], transfer?: Transferable[]) {
    this.window.postMessage({ key, data, secret: this.secret } satisfies Message, this.targetOrigin, transfer)
  }

  listen<K extends keyof Events>(callback: (event: Message<K, Events[K]>) => void) {
    const handleMessage = (event: MessageEvent<Message>) => {
      if (
        // Match source
        event.source !== this.window

        // Match origin
        || (this.targetOrigin !== '*' && event.origin !== this.targetOrigin)

        // Match secret
        || (!!this.secret && this.secret !== event.data.secret)
      ) return

      callback(event.data)
    }

    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }

  listenMethods(methods: CorsBridgeMethods<Events>) {
    this.listen(event => methods?.[event.key]?.(event.data))
    return this
  }
}

export function useCorsBridge<T>(target: MaybeRef<any>, origin: string, callback?: (bridge: CorsBridge<T>) => void) {
  return new Promise<CorsBridge<T>>((resolve) => {
    onMounted(() => {
      let node = toValue(target)

      // Parse IFrame window
      if (node instanceof HTMLIFrameElement)
        node = node.contentWindow

      if (!node || !node.postMessage)
        throw new Error('CorsBridge target is not valid')

      const bridge = new CorsBridge<T>(node, origin)
      callback?.(bridge)
      resolve(bridge)
    })
  })
}
