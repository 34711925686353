import { useUserAccount } from '../store'
import { sanitizeObject } from '../utils'
import AnalyticsWorker from './analytics-worker?worker'
import type { AnalyticsEvents, TrackingArgs } from './analytics-data'

declare global {
  interface Mixpanel {
    init: (token: string) => void
    identify: (uid: string) => void
    track: (...args: any) => void
  }
}

export class Analytics {
  static worker?: Worker

  static #run<T extends keyof Mixpanel>(method: T, ...args: Parameters<Mixpanel[T]>) {
    if ('Worker' in globalThis === false)
      return console.warn('Analytics: Worker is not supported in current context')

    const payload = sanitizeObject({
      method,
      args,
      user: useUserAccount().$state,
      defaults: {
        $current_url: location.href,
        $screen_height: screen.height,
        $screen_width: screen.width,
        $referrer: document.referrer
      }
    })

    // Load worker if not loaded
    const worker = this.worker ||= new AnalyticsWorker()
    worker.postMessage(payload)
  }

  static identify(uid: string) {
    this.#run('identify', uid)
  }

  static track<K extends keyof AnalyticsEvents>(...args: TrackingArgs<K>) {
    this.#run('track', ...args)
  }
}

/** Sugar for `Analytics.track` */
export const track = Analytics.track.bind(Analytics)
