import { account, auth } from './account'
import { affilate } from './team'
import { audio } from './audio'

export const api = {
  auth,
  account,
  audio,
  affilate
}

export type * from './types'

// Models
export * from './audio/models'
export * from './audio/utils'

export * from './account/models'
