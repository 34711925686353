import { createVNode, render } from 'vue'
import type { App, Component, ComponentInstance, SetupContext, VNode, VNodeProps } from 'vue'

interface BaseMountOptions {
  app?: App

  /** Target element to mount to */
  element?: HTMLElement
}
interface MountOptions<T extends Component> extends BaseMountOptions {
  props?: Record<string, unknown> & ComponentInstance<T>['$props'] & VNodeProps
  children?: unknown
}

export function mountVNode<T extends VNode>(vNode: T, { element, app }: BaseMountOptions = {}) {
  let el = element

  if (app && app._context)
    vNode.appContext = app._context
  if (el)
    render(vNode, el)
  else if (typeof document !== 'undefined')
    render(vNode, el = document.createElement('div'))

  const destroy = () => {
    if (el)
      render(null, el)
    el = undefined
    vNode = undefined as any
  }

  return { vNode, destroy, el }
}

export function mount<T extends Component>(component: T, { props, children, element, app }: MountOptions<T> = {}) {
  return mountVNode(
    createVNode(component, props, children),
    { app, element }
  )
}

export function useAsyncComponent<T extends Component>(importer: () => Promise<T>, options: MountOptions<T> = {}) {
  let component: T
  let result: ReturnType<typeof mount>

  return {
    mount: async () => result = mount(component ||= await importer(), options),
    destroy: () => result.destroy()
  }
}

/** Creates JSX Component */
export const defineFC = <Props = object, TComponent = Component>(builder: (props: Props, ctx: SetupContext) => TComponent) => builder
