import { http } from '..'
import type { SuccessResponse } from '../types'
import type { Affiliate } from './types'

export class Team {
  constructor(
    public members: Team.Member[] = [],
    public maxMembers = 0,
    public isBusiness = false,
    public isOwner = false
  ) {}

  get canInvite() {
    return this.isOwner && this.isBusiness
  }

  static parse(data: Team.DataRaw) {
    // Normalize data
    data.users ||= []

    // Add owner as member
    if (data.owner_email) {
      data.users.push({
        name: data.owner_name ?? '',
        email: data.owner_email ?? '',
        img: data.owner_img,
        isOwner: true
      })
    }

    return new Team(
      data.users,
      data.allowed_invites,
      data.is_business,
      data.is_owner
    )
  }
}

export namespace Team {
  export interface Member {
    name: string
    email: string
    img?: string
    isOwner?: boolean
  }

  export interface DataRaw {
    users: Member[]
    allowed_invites: number

    is_owner?: boolean
    is_business?: boolean
    is_sponsored?: boolean

    owner_name?: string
    owner_email?: string
    owner_img?: string
  }
}

export const team = {
  get: () => http.get<Team.DataRaw>('/invite').then(Team.parse),
  invite: (email: string) => http.post<SuccessResponse>('/invite', { email }),
  delete: (email: string) => http.delete('/invite', { email })
}

export const affilate = {
  get: () => http.get<Affiliate.Status>('/affiliate')
}
