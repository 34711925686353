import type { ValueOf } from 'type-fest'

/**
 * Runs function and calls `onError` when errors happen.
 * Function can be sync or async
 */
export function probeError<T, F>(fn: () => T, onError: (error?: any) => F): [F] extends [never] ? T : T | F {
  let result: any

  try {
    result = fn()

    if (result instanceof Promise)
      return result.catch(onError) as T

    return result
  }
  catch (error) {
    result = onError(error)
  }

  return result
}

/**
 * Creates error mapping for usage
 *
 * ```ts
  export const Errors = createErrorMap({
    UIDFetchFailed: 'Failed to get a new uid token from server',
    RecorderLoadFailed: 'Failed to load record assets',
  })
 
  // Create error
  const error = Errors.UIDFetchFailed()
 
  // Supply context
  Errors.UIDFetchFailed(new Error())
  Errors.UIDFetchFailed({ failed: true })
 
  // Match error
  error instanceof Errors.UIDFetchFailed // true
 * ```
 */
export function createErrorMap<const T extends Record<string, string>>(record: T) {
  type ErrorCause = Error | object | unknown | undefined
  type ErrorClass<K extends keyof T> = ReturnType<typeof createErrorClass<K>> & {
    name: K
    message: T[K]
  }

  const createErrorClass = <K extends keyof T>(key: K) => class DefinedError extends Error {
    static name = key
    static message = record[key]

    constructor(cause?: ErrorCause) {
      super(record[key], { cause })
      this.name = String(key)

      // Merge stacks
      if (cause instanceof Error && this.stack)
        this.stack += `\n\n${cause.stack}`
    }

    /** Checks if provided error is same */
    static matches(error?: ErrorClass<any> | Error): error is ErrorClass<K> {
      return error
        ? error instanceof DefinedError || error === DefinedError || error === this
        : false
    }

    /** This will append the error context when the function throws an error */
    static run = <R>(fn: () => R) => probeError(fn, (error?: ErrorClass<K>) => {
      throw new DefinedError(error)
    })

    /** This will append the error context when the function throws an error */
    static runFallback<R, F>(fn: () => R, onError: (error?: ErrorClass<K>) => F) {
      return probeError(fn, onError)
    }
  }

  const errorClasses = Object.keys(record).reduce(
    (map, key: keyof T) => {
      map[key] = createErrorClass(key)
      return map
    },
    {} as { [K in keyof T]: ErrorClass<K> }
  )

  /** Checks if provided error matches any in the record */
  function includes(error?: Error) {
    return error
      ? Object.keys(record).find(key => key === error.name)
      : false
  }

  return Object.assign(errorClasses, { includes })
}

/** Infer Error type created from {@link createErrorMap} */
export type InferErrorFromMap<T extends ReturnType<typeof createErrorMap> | object> = ValueOf<Omit<T, 'includes'>>
