import { ref } from 'vue'
import { defineStore } from 'pinia'
import { http } from '../http'
import { useSettings, useUserAccount } from '../store'
import { useLogger } from '../diagnostics/logger'
import { Analytics } from '../diagnostics/analytics'
import { ErrorService } from '../diagnostics/error-service'
import { openPopup, promiseDebounce } from '..'

const log = useLogger('⚙️ Session:')

// Has to update ErrorService, Analytics, Account related Stores
// Has to trigger dependency updates when auth state changes

export const useSession = defineStore('session-service', () => {
  /** Whether the core components are ready */
  const isReady = ref(false)

  /** Whether the user is authorized */
  const isAuthorized = ref(false)

  /** Whether user is authorized and first sync has finished */
  const isSynced = ref(false)

  // Stores
  const user = useUserAccount()
  const settings = useSettings()

  // Methods
  function logout() {
    if (!http.isAuthorized)
      return

    isAuthorized.value = isSynced.value = false
    http.logout()

    log('logged out')
  }

  const sync = promiseDebounce(async () => {
    if (!http.isAuthorized) {
      log('sync requested. Waiting for authorization')
      await http.onAuthorized()
    }

    log('syncing stores with server...')

    await Promise.all([
      user.sync.refresh(),
      settings.sync.refresh()
    ])

    isSynced.value = true
    log('stores synced successfully')
  })

  // Event methods
  const onAuthorized = promiseDebounce(async () => {
    const uid = String(user.id)
    isAuthorized.value = true

    await sync()

    // Identify services
    Analytics.identify(uid)
    ErrorService.identify({ ...user.$state })

    log(`authorized user -> ${user.email} (${uid})`)
  })

  async function init() {
    log('initializing...')
    isReady.value = true
    if (http.isAuthorized)
      await onAuthorized()
  }

  /** Starts payment flow */
  async function openPaymentFlow() {
    const url = http.getAuthURL('payment', null)
    await openPopup(url, 384, 782)
    await sync()
  }

  // Events
  http.on('init', init)
  http.on('authorized', onAuthorized)
  http.on('logout', logout)

  if (http.isReady)
    init()

  return { isReady, isAuthorized, isSynced, user, settings, sync, logout, onAuthorized, openPaymentFlow }
})
