import { ref } from 'vue'
import { downloadFile, sendSupportMail } from '../utils'
import { useUserAccount } from '../store'
import { Logger } from './logger'
import type { ErrorService, SentrySDK } from './error-service'

export interface ReportContext {
  /** Sentry generated event id. Can be retrieved from `ErrorService.log`. An error will be generated if not provided */
  eventId?: string
  subject?: string

  /** Past recorded sentry events */
  errorLogs?: ErrorService.ErrorLog[]
}

export interface ReporterUIProps extends ReportContext {
  sdk: SentrySDK
  onClose: () => void
}

export function ReporterUI(props: ReporterUIProps) {
  const messageRef = ref<HTMLTextAreaElement>()

  function getLogs() {
    let logs = Logger.dump()

    // Add sentry errors to logs
    if (props.errorLogs) {
      logs += props.errorLogs.map((log, index) => {
        const { timestamp, error } = log

        const stack = error?.stack || error
        const date = typeof timestamp === 'number'
          ? new Date(timestamp * 1000)
          : timestamp

        return `[${index + 1}] Sentry ID: ${log.id}\nTimestamp: ${date}\n\n${stack}`
      }).join('\n\n')
    }

    return logs
  }

  async function submit(event: Event) {
    event.preventDefault()

    const user = useUserAccount()
    const feedback = messageRef.value?.textContent || ''
    const { eventId, sdk: sentry, subject = 'Bug Report' } = props

    const logs = getLogs()
    const sid = eventId
      ?? sentry.captureException(new Error('Report issue'), scope => scope.addAttachment({ filename: 'logs.txt', data: logs }))

    // Attach user feedback
    sentry.captureUserFeedback({
      event_id: sid,
      comments: feedback,
      email: user.email,
      name: user.name
    })

    // Save bug report in user machine
    downloadFile(new Blob([logs]), `Vocal Bug Report - ${sid} - ${new Date()}.txt`)

    // Send email
    sendSupportMail(subject, `SID: ${sid}\n\n${feedback}\n\nInternal Logs\n${logs}`.slice(0, 1600))
    props.onClose()
  }

  function onBlur(event: MouseEvent) {
    if (event.target === event.currentTarget)
      props.onClose()
  }

  return (
    <div class="fixed inset-0 flex-center bg-utility-overlay animate-(name-fade ease duration-300)" onClick={onBlur}>
      <section class="m-4 p-6 w-full max-w-400px bg-shade-light rounded-15px">
        <h2 class="mb-2 gap-2 text-(2xl neutral-800) font-bold flex items-center">
          <i-mdi-alert-circle class="text-red-5" />
          {' '}
          Report Issue
        </h2>

        <p class="text-(base neutral-500)">
          To better understand, and fix the issue please try to provide the following information.
        </p>

        <ul
          class="mt-4 mb-10 gap-2 grid [&_svg]:text-(2xl neutral-500) [&>li]:(gap-2 flex items-center text-(base neutral-600))"
        >
          <li>
            <i-solar-eye-bold-duotone />
            How and where it happens?
          </li>
          <li>
            <i-solar-dna-bold-duotone />
            Any specific way to reproduce this bug?
          </li>
        </ul>

        <form class="grid gap-4" onSubmit={submit}>
          <textarea
            ref={messageRef}
            required
            minlength="10"
            name="reporter-form"
            placeholder="I was doing this ... when it happended ..."
            class="dom-input-styles min-h-120px"
          />

          <button class="h-11 w-full bg-primary-500 text-(primary-100 base) font-medium rounded" type="submit">
            Send Report
          </button>
        </form>
      </section>
    </div>
  )
}
